import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  private darkModeEnabled: boolean;

  constructor() {
    // Check local storage for dark mode preference
    const checkDarkModeStatus = localStorage.getItem('darkMode');
    this.darkModeEnabled = checkDarkModeStatus === '1';

  }

  toggleDarkMode(enabled: boolean): void {
    const pageBody = document.querySelector("body");

    if (enabled) {
      pageBody.classList.add('dark-mode');
      pageBody.classList.remove('bg-white');
      localStorage.setItem('darkMode', '1');
    } else {
      pageBody.classList.remove('dark-mode');
      // pageBody.classList.add('bg-white');
      localStorage.setItem('darkMode', '0');
    }
    this.darkModeEnabled = enabled;
  }

  isDarkModeEnabled(): boolean {
    return this.darkModeEnabled;
  }
}
