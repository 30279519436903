import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UserAccountService } from './user-account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private tokenEndpoint = `${environment.keycloakConfig.url}/realms/${environment.keycloakConfig.realm}/protocol/openid-connect/token`;
  private logoutEndpoint = `${environment.keycloakConfig.url}/realms/${environment.keycloakConfig.realm}/protocol/openid-connect/logout`;
  private clientId = environment.keycloakConfig.clientId;
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);
  private refreshTokenSubscription?: Subscription;

  constructor(private router: Router,
              private http: HttpClient,
              private cookieService: CookieService,
              private userService: UserAccountService) {
    this.restoreSession();
  }

  /** Logs in and stores tokens in cookies */
  login(username: string, password: string, redirectUrl?: string): Observable<TokenResponse> {
    const body = new HttpParams()
      .set('client_id', this.clientId)
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password);

    return this.http.post<TokenResponse>(this.tokenEndpoint, body.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).pipe(
      tap(res => {
        this.storeTokens(res);
        this.userService.fetchUser()
        if (redirectUrl) {
          this.router.navigateByUrl(redirectUrl);
        }
      })
    );
  }

  /** Restores authentication state on page reload */
  private restoreSession() {
    const accessToken = this.cookieService.get('access_token');
    const refreshToken = this.cookieService.get('refresh_token');

    if (accessToken) {
      this.isAuthenticated$.next(true);
      this.scheduleTokenRefresh();
      return;
    }

    if (refreshToken) {
      this.refreshToken().subscribe();
    }
  }

  /** Refreshes the access token */
  refreshToken(): Observable<TokenResponse> {
    const refreshToken = this.cookieService.get('refresh_token');
    if (!refreshToken) {
      this.logout();
      return new Observable();
    }

    const body = new HttpParams()
      .set('client_id', this.clientId)
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken);

    return this.http.post<TokenResponse>(this.tokenEndpoint, body.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).pipe(
      tap(res => {
        this.storeTokens(res);
      }),
      tap(() => this.isAuthenticated$.next(true))
    );
  }

  /** Logs out and clears tokens */
  logout() {
    const refreshToken = this.cookieService.get('refresh_token');
    if (!refreshToken) {
      this.clearSession();
      return;
    }

    const body = new HttpParams()
      .set('client_id', this.clientId)
      .set('refresh_token', refreshToken);


    this.http.post(this.logoutEndpoint, body.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).subscribe().add(() => this.clearSession());
  }

  get isAuthenticated(): boolean {
    return this.isAuthenticated$.value;
  }

  /** Clears tokens from cookies */
  public clearSession() {
    this.cookieService.delete('access_token', '/');
    this.cookieService.delete('refresh_token', '/');
    this.isAuthenticated$.next(false);
    this.userService.clearUser();
    this.cancelTokenRefresh();
    this.router.navigateByUrl('/login');
  }

  /** Stores tokens in cookies */
  private storeTokens(res: TokenResponse) {
    const tokenExpiration = new Date(new Date().getTime() + res.expires_in * 1000);
    const refreshExpiration = new Date(new Date().getTime() + res.refresh_expires_in * 1000);

    this.cookieService.set('access_token', res.access_token, tokenExpiration, '/');
    this.cookieService.set('refresh_token', res.refresh_token, refreshExpiration, '/');

    this.isAuthenticated$.next(true);
    this.scheduleTokenRefresh(res.expires_in);
  }

  /** Schedules token refresh 30 seconds before it expires */
  private scheduleTokenRefresh(expiresIn?: number) {
    this.cancelTokenRefresh();

    if (!expiresIn) {
      expiresIn = parseInt(this.cookieService.get('access_token_expiry'), 10);
    }

    const refreshTime = (expiresIn - 30) * 1000; // Refresh 30 seconds before expiry
    if (refreshTime > 0) {
      this.refreshTokenSubscription = timer(refreshTime).subscribe(() => {
        this.refreshToken().subscribe();
      });
    }
  }

  /** Cancels the scheduled token refresh */
  private cancelTokenRefresh() {
    if (this.refreshTokenSubscription) {
      this.refreshTokenSubscription.unsubscribe();
    }
  }

  /** Retrieves access token */
  get accessToken(): string {
    return this.cookieService.get('access_token') || '';
  }

  ngOnDestroy() {
    this.cancelTokenRefresh();
  }
}

/** Token response interface */
export interface TokenResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  refresh_expires_in: number;
  token_type: string;
}
