import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AsyncSubject, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PageManager} from './page-manager';
import { User } from '../models/user/user.model';
import { UserAccount } from '../models/account/user-account.model';
import { PermissionType } from '../models/account/permission-type.enum';
import { environment } from '../environments/environment';
import { Constants } from '../models/enum/constants';


@Injectable({
  providedIn: 'root'
})
export class UserAccountService {
  public static _user: User;
  private static user: Subject<User | undefined> = new ReplaySubject(undefined);
  private static ongoingFetch: Observable<any> | null;
  private static initialized: boolean;
  public static _currentUserAccount: UserAccount | undefined;
  private static currentUserAccount$: Subject<UserAccount | null> = new ReplaySubject(undefined);
  public allAccounts!: UserAccount[];

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private pageManager: PageManager,
  ) {

    UserAccountService.user.subscribe((user: any) => {
      if (user === undefined) {
        UserAccountService._currentUserAccount = undefined;
        UserAccountService.currentUserAccount$.next(UserAccountService._currentUserAccount!);
        return;
      }
      UserAccountService.initialized = true;
      UserAccountService._user = user;
      if (user?.accounts) {
        this.allAccounts = user.accounts.map((it: any) => new UserAccount(it));
        let firstBusinessAccount = this.allAccounts.find(
          (value) => value.accountType == 'ORGANIZATION'
        );
        let lastCreatedBusiness = null;
        if (sessionStorage.getItem('LAST_CREATED_ORG_ACC_CODE')) {
          lastCreatedBusiness = this.allAccounts.find(
            (value) => value.accountCode == sessionStorage.getItem('LAST_CREATED_ORG_ACC_CODE')
          );
          sessionStorage.removeItem('LAST_CREATED_ORG_ACC_CODE');
          firstBusinessAccount = lastCreatedBusiness || firstBusinessAccount;
        }
        let accInStorage = this.getCurrentUserAccountInStorage(user);
        if (!accInStorage) {
          accInStorage = this.allAccounts[0];
        }
        if (accInStorage && accInStorage.accountType != 'ORGANIZATION' && firstBusinessAccount) {
          accInStorage = firstBusinessAccount;
        }
        UserAccountService._currentUserAccount = accInStorage;
        UserAccountService.currentUserAccount$.next(UserAccountService._currentUserAccount);
      }
    });
  }


  private permissions(): string[] {
    const currentAccount = UserAccountService._currentUserAccount;
    if (!currentAccount) {
      return [];
    }
    return currentAccount.permissions;
  }

  public setCurrentAccount(account: UserAccount): void {
    this.setCurrentUserAccount(account);
  }

  public hasPermission(permissionName: string | PermissionType): boolean {
    return this.permissions().filter((it: string) => it === permissionName).length > 0;
  }

  public hasAnyPermission(permissions: string[] | PermissionType[]): boolean {
    for (const permission of permissions) {
      if (this.hasPermission(permission)) {
        return true;
      }
    }
    return false;
  }

  hasRole(role: string): boolean {
    return UserAccountService._currentUserAccount?.roles.find((value: string) => value?.toUpperCase() == role?.toLowerCase()) != null;
  }

  hasAnyRole(roles: string[]): boolean {
    for (const role of roles) {
      if (this.hasRole(role)) {
        return true;
      }
    }
    return false;
  }

  public hasAccountType(accountType: string): boolean {
    return UserAccountService._currentUserAccount?.accountType === accountType;
  }

  public requestPasswordReset(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}/password/forgot`, data);
  }

  public getUser(): Subject<User | undefined> {
    return UserAccountService.user;
  }

  public forbidAccess(): void {
    this.router.navigate(['/forbidden']);
  }

  public fetchUser(): Observable<User> {
    if (UserAccountService.initialized) {
      return of(UserAccountService._user);
    }
    return this.fetch();
  }


  private fetch(): Observable<any> {
    const wrapper = new AsyncSubject();
    UserAccountService.ongoingFetch = wrapper;

    this.httpClient.get(`${environment.apiBaseUrl}/user/me`).subscribe(
      (u: any) => {
        const user = new User(u);
        wrapper.next(user);
        wrapper.complete();

        UserAccountService.user.next(user);
        UserAccountService.ongoingFetch = null;
      },
      (err: unknown) => {
        wrapper.error(err);
        UserAccountService.user.next(undefined);
      }
    );

    return UserAccountService.ongoingFetch;
  }

  private getCurrentUserAccountFromStorage(): UserAccount | undefined {
    return this.pageManager.getData(
      'USER_ACCOUNT',
      'currentAccount',
      Constants.Storage.LOCAL
    ) as UserAccount;
  }

  public getCurrentAccount(): UserAccount | null {
    const data = this.pageManager.getData('USER_ACCOUNT', 'currentAccount');
    return data ? (data as UserAccount) : null;
  }

  private setCurrentUserAccount(userAccount: UserAccount): void {
    if (!userAccount) {
      UserAccountService.currentUserAccount$.next(this.getCurrentUserAccountFromStorage()!);
    } else {
      UserAccountService.currentUserAccount$.next(userAccount);
      this.pageManager.storeData(
        'USER_ACCOUNT',
        'currentAccount',
        userAccount,
        Constants.Storage.LOCAL
      );
    }
  }

  private getCurrentUserAccountInStorage(user: User): UserAccount | null | undefined {
    const accountInStorage: UserAccount | undefined = this.getCurrentUserAccountFromStorage();
    if (!accountInStorage) {
      return null;
    }
    return this.allAccounts.find((value) => value.accountCode == accountInStorage.accountCode);
  }

  clearUser(): void {
    UserAccountService._user = undefined;
    UserAccountService.user.next(undefined);
    UserAccountService.initialized = false;
  }
}
